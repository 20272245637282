import React from 'react'
import logo from '../assets/svg/logo.svg'
import youtube from '../assets/images/youtube.png'
import instagram from '../assets/images/instagram.png'
import facebook from '../assets/images/facebook.png'
import { useNavigate } from 'react-router'
import { SIGNIN, SIGNUP } from '../constants'

const Footer = () => {
    const Navigate = useNavigate()
    return (
        <div id='footer-wrapper'>
            {/* <div id='subscribe-wrapper'>
                <div>
                    <span className='heading'>Subscribe</span>
                    <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mollis pretium.</small>
                </div>
                <div id='subscribe-email-wrapper' className='gradient-border'>
                    <input type="text" defaultValue={"Email"} />
                    <button className='gradient-border'>Continue</button>
                </div>
            </div> */}

            <div id='footer'>
                <div id='footer-links'>
                    <div>
                        <span onClick={() => window.open("https://paybrise.app/docs/")}>Documentation</span>
                        <span onClick={() => Navigate("/pricing")}>Pricing</span>
                        <span onClick={() => Navigate('/homepage')}>Home</span>
                        <span onClick={() => window.location.href = SIGNIN}>Sign In</span>
                        <span onClick={() => window.location.href = SIGNUP}>Get Started</span>
                        <span onClick={() => Navigate("/contact-us")}>Contact Us</span>
                    </div>
                </div>
                <div id='follow-links-wrapper'>
                    <div id='logo-title-wrapper'>
                        <img src={logo} alt="bitgirt" style={{ height: "65px", width: "65px" }} />
                        <h2>PayBrise</h2>
                    </div>
                    {/* <div id="footer-follow-links">
                        <span>Follow us</span>
                        <div>
                            <img src={youtube} alt="youtube" width={32} />
                            <img src={instagram} alt="instagram" width={32} />
                            <img src={facebook} alt="facebook" width={32} />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Footer