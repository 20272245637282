import './styles/pages.css';
import './styles/responsive.css';
import './styles/App.css';

import { Navigate, Route, Routes } from "react-router-dom";
import Navbar from './components/navbar';
import Homepage from './pages/homepage';
import Footer from './components/footer';
import AboutUs from './pages/aboutus';
// import MerchantRegistration from './pages/merchantRegistration';
import Pricing from './pages/pricing';
import ContactUs from './pages/contactus';

const App = () => {
  return (
    <div id='app'>
      <Routes>
        <Route path="/" element={<Navigate to={"/homepage"} />} />
        <Route path="/homepage" element={<PageWrapper Page={Homepage} />} />
        <Route path="/about-us" element={<PageWrapper Page={AboutUs} />} />
        <Route path="/pricing" element={<PageWrapper Page={Pricing} />} />
        <Route path="/contact-us" element={<PageWrapper Page={ContactUs} />} />
        {/* <Route path="/merchant-registration" element={<MerchantRegistration />} /> */}
      </Routes>
    </div>
  );
}

const PageWrapper = ({ Page }) => (
  <div id='page-content-wrapper'>
    <Navbar />
    <Page />
    <Footer />
  </div>
)

export default App;