import React from 'react'
import pricingbg from '../assets/images/pricing.png';
import pricingService from '../assets/images/pricing-service.png';

const Pricing = () => {
    return (
        <div id='pricing'>
            <div id='left'>
                <div>
                    <h2>Pay As</h2>
                    <h2>You Use</h2>
                    <p>We offer the lowest fee for the settlement of your payouts, more volume, less fees.</p>
                </div>
                <div id='layout-image-wrapper'>
                    <img src={pricingbg} alt="pricingbg" />
                </div>
            </div>
            <div id='details'>
                <div id='top-content'>
                    <div>
                        <div>
                            <span>0.02%</span>
                            <p>0.02% fee on volume up-to 5 Billion BRISE</p>
                        </div>
                        <div>
                            <span>0.01%</span>
                            <p>0.01% fee after you surpass 5 Billion BRISE in total volume</p>
                        </div>
                    </div>
                    {/* <div>
                        <span>18% GST applicable on all transactions</span>
                        <button className='gradient-btn'>Get Started</button>
                    </div> */}
                </div>
                <div id='bottom-content'>
                    <div>
                        <span>Customer Support</span>
                        <p>Ask us anything and we'll help you find the answers. Response time: within 24 hours</p>
                    </div>
                    <img src={pricingService} alt="pricing-service" />
                </div>
            </div>
        </div>
    )
}

export default Pricing