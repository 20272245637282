import React from 'react'
import vectorDashboard from "../assets/images/trimmed-dashboard.jpg"
// import reviews from "../assets/images/reviews.png"
import homepageIcon from "../assets/svg/homepage.svg"
import homepageCard1 from "../assets/svg/homepage-card1.svg"
import homepageCard2 from "../assets/svg/homepage-card2.svg"
import homepageCard3 from "../assets/svg/homepage-card3.svg"
import homepageCard4 from "../assets/svg/homepage-card4.svg"
import coins from "../assets/svg/coins.svg"
import creditcard from "../assets/svg/creditcard.svg"
import payout from "../assets/svg/payout.svg"
import user from "../assets/svg/user.svg"
import { SIGNUP } from '../constants'
// import partner from "../assets/svg/partner.svg"
// import { ReactComponent as StarIco } from "../assets/svg/star.svg"

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// const sliderSettings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     centerMode: true,
//     variableWidth: true
// };

const Homepage = () => {
    const openSignUp = () => window.location.href = SIGNUP
    return (
        <div id="homepage">
            <div id='homepage-intro'>
                <div>
                    <h2 className='text-centralized heading'>Start Accepting</h2>
                    <h2 className='text-centralized heading'>$BRISE Payments</h2>
                    <h2 className='gradient-text large text-centralized'>NOW</h2>
                    <ul>
                        <li>Accept BRISE as payment</li>
                        <li>Instant settlement to your wallet</li>
                        <li>24/7 support</li>
                        <li>Lowest fees</li>
                        <li>Feel the freedom of choice with our variety of tools</li>
                    </ul>
                    <button className="gradient-btn elem-centralized" onClick={openSignUp}>Sign Up</button>
                </div>
                <div>
                    <img src={vectorDashboard} alt="vector-dashboard" />
                </div>
            </div>
            <div id='homepage-image-container'>
                <img src={homepageIcon} alt="homepage" />
            </div>
            <div id='easy-integration'>
                <div id='text-container'>
                    <h3 className='heading'>Easy integration and setup</h3>
                    <p>Accept payments and donations in BRISE</p>
                </div>
                <div id='cards-wrapper'>
                    <Card headline={"Manage your money, your way"} imgsrc={homepageCard1} />
                    <Card headline={"Insights to power your business"} imgsrc={homepageCard2} />
                    <Card headline={"Easy integrations to go live in minutes"} imgsrc={homepageCard3} />
                    <Card headline={"Total Flexibility in Settlements"} imgsrc={homepageCard4} />
                </div>
                {/* <button className="gradient-btn" style={{ translate: "calc(50vw - 50%)" }}>START ACCEPTING CRYPTO</button> */}
            </div>
            <div id='benefits'>
                <h3 className='heading'>With PayBrise You Get</h3>
                <div id='long-cards-wrapper'>
                    <LongCard headline={"BRISE Coin"} description={"Accept BRISE as payment on your online store / website"} imgsrc={coins} />
                    <LongCard headline={"Low Fees"} description={"Pay less our fees are the lowest in the market, starting from 0.01%"} imgsrc={creditcard} />
                    <LongCard headline={"Instant Settlements"} description={"Receive your funds directly to your wallet"} imgsrc={payout} />
                    <LongCard headline={"User Friendly"} description={"Friendly merchant panel, easy to manage your payments"} imgsrc={user} />
                </div>
            </div>
            {/* <div id="our-partners">
                <h3 className='heading'>Our Partners</h3>
                <div id='partner-images-wrapper'>
                    <img src={partner} alt="partner" width={192} />
                    <img src={partner} alt="partner" width={192} />
                    <img src={partner} alt="partner" width={192} />
                    <img src={partner} alt="partner" width={192} />
                    <img src={partner} alt="partner" width={192} />
                    <img src={partner} alt="partner" width={192} />
                    <img src={partner} alt="partner" width={192} />
                    <img src={partner} alt="partner" width={192} />
                </div>
                <div
                    id="reviews-wrapper"
                    onMouseDown={e => e.target.style.cursor = "grabbing"}
                    onMouseUp={e => e.target.style.cursor = "grab"}
                >
                    <Slider {...sliderSettings}>
                        <ReviewCard />
                        <ReviewCard />
                        <ReviewCard />
                    </Slider>
                </div>
            </div> */}
        </div>
    )
}

const Card = ({ headline, imgsrc }) => (
    <div className='homepage-card'>
        <div><img src={imgsrc} alt="card1" width={96} /></div>
        <span>{headline}</span>
    </div>
)

const LongCard = ({ headline, description, imgsrc }) => (
    <div className='long-card'>
        <div className='icon-wrapper'>
            <img src={imgsrc} alt={headline} width={96} />
        </div>
        <div className='text-wrapper'>
            <h2>{headline}</h2>
            <p>
                {description ||
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. At mauris auctor dis purus amet felis, vitae. Donec lorem facilisis nulla erat in posuere tristique malesuada."}
            </p>
        </div>
    </div>
)

// const ReviewCard = () => (
//     <div className='review-card-wrapper'>
//         <div className='review-card'>
//             <div className='review-head'>
//                 <div><img src={reviews} alt="review" width={96} /></div>
//                 <div>
//                     <span>Fredi Allan</span>
//                     <small>Founder, ABC</small>
//                 </div>
//             </div>
//             <div className='review-text-wrapper'>
//                 <q>Time and money saving</q>
//                 <p>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit ut erat ligula ullamcorper quisque sed libero. Metus diam, ultricies et diam dapibus purus sed nunc accumsan. Tristique ultrices vestibulum vulputate curabitur nullam turpis. Diam sed id lorem sed.
//                 </p>
//             </div>
//             <div className='review-stars-wrapper'>
//                 <StarIco />
//                 <StarIco />
//                 <StarIco />
//                 <StarIco />
//                 <StarIco />
//             </div>
//         </div>
//     </div>
// )

export default Homepage