import React, { useState } from 'react'
import contact from '../assets/images/contact.png'
// import phone from '../assets/svg/phone.svg'

const ContactUs = () => {

    const [formData, setFormData] = useState({ mailto: "support@paybrise.app" })
    const handleDataEnter = e => setFormData(i => ({ ...i, [e.target.name]: e.target.value }))
    
    return (
        <div id='contact-us'>
            <div id="head">
                <div>
                    <h2>Contact Us</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa enim enim commodo et velit, et accumsan lectus id.</p> */}
                </div>
                <img src={contact} alt="contact" />
            </div>

            {/* 
                <div id='contact-details'>
                    <div>
                        <span>Contact Us</span>
                        <span>080-68727374 / 022-68727374</span>
                        <span>080-68727374 / 022-68727374</span>
                    </div>
                    <div id='phone-icon-wrapper'>
                        <div>
                            <img src={phone} alt="phone" />
                        </div>
                    </div>
                    <div>
                        <span>Contact Us</span>
                        <span>080-68727374 / 022-68727374</span>
                        <span>080-68727374 / 022-68727374</span>
                    </div>
                </div>
            */}

            <div id='need-more-help' className='gradient-border'>
                <h2>Need more help?</h2>
                <div id='inputs'>
                    <div className='gradient-border'>
                        <input type="text" className='user-input-elem' name="name" value={formData?.name} onChange={handleDataEnter} placeholder='Full Name' required />
                    </div>
                    <div className='gradient-border'>
                        <input type="text" className='user-input-elem' name='email' value={formData?.email} onChange={handleDataEnter} placeholder='Email' required />
                    </div>
                    <div className='gradient-border'>
                        <input type="text" name="subject" value={formData?.subject} onChange={handleDataEnter} className='user-input-elem' placeholder='Subject' required />
                    </div>
                    <div className='gradient-border'>
                        <textarea name="message" value={formData?.message} onChange={handleDataEnter} className='user-input-elem' id="" cols="30" rows="10" placeholder='Type a massage' required></textarea>
                    </div>
                </div>
                <div id='help-buttons'>
                    <button className='gradient-btn'>RESET</button>
                    <a href={`mailto:${formData?.mailto}?name=${formData?.name}&subject=${formData?.subject}&body=${formData?.message}`}>
                        <button type='submit' className='gradient-btn' value="Send">SEND</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ContactUs