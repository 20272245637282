import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import logo from '../assets/svg/logo.svg'
import menu from '../assets/svg/menu.svg'
import cross from '../assets/svg/cross.svg'
import { SIGNIN, SIGNUP } from '../constants';

const options = [
    { label: "Home Page", path: "homepage" },
    { label: "Documentation", path: "docs", redirect: 'https://paybrise.app/docs/' },
    { label: "Pricing", path: "pricing" },
    { label: "Contact Us", path: "contact-us" },
    { label: "Sign In", redirect: SIGNIN, themeBtn: true },
    { label: "Get Started", redirect: SIGNUP, themeBtn: true },
]

const Navbar = () => {

    const Navigate = useNavigate()
    const [selected, setSelected] = useState(options?.find(i => window.location.pathname.includes(i.path))?.path)
    const [menuVisible, setMenuVisible] = useState(false)
    const onClick = i => {
        if (i?.redirect) return window.open(i.redirect);
        Navigate(`/${i.path}`)
        menuVisible && setMenuVisible(false)
        setSelected(i.path)
    }

    return (
        <div id='navbar-wrapper'>
            <div id='logo-title-wrapper'>
                <img src={logo} alt="bitgirt" />
                <h2>PayBrise</h2>
            </div>
            <div id='nav-actions-wrapper'>
                {options?.map(i => <button
                    className={`${i.themeBtn ? "gradient-btn " : "nav-actions"} ${selected === i.path ? 'selected' : ''}`}
                    onClick={() => onClick(i)}
                >{i.label}</button>)}
            </div>
            <div id='hamburger-menu-wrapper'>
                <div id='menu-button' onClick={e => setMenuVisible(!menuVisible)}>
                    <img src={menu} alt="menu" width={48} />
                </div>
                <div id='nav-menu' className={`gradient-border ${menuVisible ? 'active' : 'false'}`}>
                    <span onClick={e => setMenuVisible(!menuVisible)}><img src={cross} alt="cross" /></span>
                    {options?.map(i => <button
                        className={`${i.themeBtn ? "gradient-btn " : "nav-actions"} ${selected === i.path ? 'selected' : ''}`}
                        onClick={() => onClick(i)}
                    >{i.label}</button>)}
                </div>
            </div>
        </div>
    )
}

export default Navbar