import React from 'react'

import dashboard from "../assets/images/dashboard.jpg";
import transactions from "../assets/images/transactions.jpg";
import commission from "../assets/images/commission.jpg";
import partner from "../assets/svg/partner.svg"

import leftImg from "../assets/images/about/left.jpg"
import middleImg from "../assets/images/about/middle.jpg"
import rightImg from "../assets/images/about/right.jpg"
import review1 from "../assets/images/about/review1.jpg"
import review2 from "../assets/images/about/review2.jpg"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true
};

const AboutUs = () => {
    return (
        <div id='about-us'>
            <div>
                <div id='about-top'>
                    <h2 className="heading">Accept payments with</h2>
                    <h2 className="heading">brisepay Payment Gateway</h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa enim enim commodo et velit, et accumsan lectus id. Laoreet libero mi cras faucibus et a vitae, arcu. Aliquet elementum faucibus auctor non. Auctor mi et magna semper eget. Erat amet velit tincidunt sagittis id sed curabitur. Consequat lorem egestas lacus.
                    </p>
                </div>
                <div
                    id='brise-corousal'
                    onMouseDown={e => e.target.style.cursor = "grabbing"}
                    onMouseUp={e => e.target.style.cursor = "grab"}
                >
                    <Slider {...sliderSettings}>
                        <div className='img-wrapper'>
                            <div><img src={dashboard} alt="dashboard" /></div>
                        </div>
                        <div className='img-wrapper'>
                            <div><img src={transactions} alt="transactions" /></div>
                        </div>
                        <div className='img-wrapper'>
                            <div><img src={commission} alt="commission" /></div>
                        </div>
                    </Slider>
                </div>
            </div>
            <div id='companies'>
                <img src={partner} alt="partner" />
                <img src={partner} alt="partner" />
                <img src={partner} alt="partner" />
                <img src={partner} alt="partner" />
            </div>
            <div id='about-section'>
                <h2 className="heading">About Us</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna phasellus dignissim ornare rhoncus vitae. Quis et, pharetra, eu velit a sit in elit fermentum. Fusce morbi adipiscing nulla morbi tempor. At mauris et eu tellus. Facilisi cursus amet eu, amet, eget viverra vitae fusce. Posuere habitasse justo, diam magna bibendum. Nunc amet, consequat morbi vitae vulputate sit nisl dignissim in. Ridiculus blandit volutpat ut eu potenti eu feugiat. Morbi justo, porta scelerisque suspendisse lorem quisque a ipsum ac.
                </p>
                <p>
                    Porttitor purus id vel tristique condimentum. Quisque ut tortor lectus laoreet tellus lacus. Amet, tristique orci magna convallis etiam euismod. Viverra in porttitor malesuada pellentesque. Consectetur vestibulum placerat nunc ut blandit felis elementum. Magna erat sollicitudin pharetra id mattis enim. Tempus, sagittis tortor, volutpat elit. Leo elementum, non vitae massa sapien purus. Duis augue egestas id blandit in vel, blandit egestas rutrum.
                </p>
                <p>
                    Pellentesque nunc placerat elementum in morbi facilisis viverra vel justo. Urna sit senectus integer egestas sit id ante congue. Aliquam id ac tincidunt varius imperdiet interdum. Viverra eget nascetur sapien, risus lacus sed nunc, dui. Nibh ut ac tristique risus lorem mauris, sollicitudin tincidunt risus. Non laoreet accumsan nunc, est accumsan ut ultricies lectus.
                </p>
                <div id='images-wrapper'>
                    <img src={leftImg} alt="left" />
                    <img src={middleImg} alt="center" />
                    <img src={rightImg} alt="right" />
                </div>
            </div>
            <div id='our-mission'>
                <h2 className='heading'>Our Mission</h2>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra urna facilisis hendrerit sagittis, mattis venenatis. Auctor ultrices commodo egestas nisl bibendum ultrices non. Eu suspendisse vestibulum nunc turpis facilisis in nec tellus est. Purus in tellus morbi urna convallis maecenas risus, vehicula. Mattis adipiscing in id risus tellus lorem scelerisque. Et mattis amet imperdiet leo, tortor, urna purus sed. Pharetra dignissim aenean eget euismod sit posuere varius. Non in adipiscing pharetra elit lorem nec, neque. At vel, massa accumsan et tristique venenatis morbi morbi duis. Sed auctor quam pharetra molestie sit elementum nibh amet. Mattis pulvinar nunc bibendum ornare diam.
                </p>
            </div>
            <div id='partner-reviews-section'>
                <h2 className="heading">People Who Partner With Us</h2>
                <div
                    id="partner-reviews"
                    onMouseDown={e => e.target.style.cursor = "grabbing"}
                    onMouseUp={e => e.target.style.cursor = "grab"}
                >
                    <Slider {...sliderSettings} infinite={true}>
                        <PartnerReviewCard imgsrc={review1} />
                        <PartnerReviewCard imgsrc={review2} />
                    </Slider>
                </div>
            </div>
            <div id='questions-wrapper'>
                <h2 className="heading">Frequently Asked Questions</h2>
                <div id='questions'>
                    <div>
                        <span>What is Partner Program?</span>
                        <button>+</button>
                    </div>
                    <div>
                        <span>Is it easy to set up?</span>
                        <button>+</button>
                    </div>
                    <div>
                        <span>How do I become a refer a Client</span>
                        <button>+</button>
                    </div>
                    <div>
                        <span>What documents will I need to sign up?</span>
                        <button>+</button>
                    </div>
                    <div>
                        <span>How can I get in touch?</span>
                        <button>+</button>
                    </div>
                </div>
                <button className='gradient-btn'>VIEW ALL</button>
            </div>
        </div >
    )
}

const PartnerReviewCard = ({ imgsrc }) => {
    return (
        <div className='partner-review-card'>
            <div className='profile'>
                <div className='image-wrapper'>
                    <img src={imgsrc} alt="review" />
                </div>
                <div>
                    <span>Fredi Allan </span>
                    <small>Certified Financial Planner</small>
                </div>
            </div>
            <div className='review-text'>
                <q>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci odio.
                </q>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra congue bibendum nisi, mi. Eget nam cras non in tincidunt. Mi nullam sit risus, mattis amet odio. Augue dictum ut orci duis. Justo sit cum tellus id magna mi sit.
                </p>
            </div>
        </div>
    )
}

export default AboutUs